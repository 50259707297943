var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("TheOrganizationAddSpace", {
    attrs: {
      newSpaceType: _vm.spaceTypes.VENDOR_SPACE,
      spaceTypeIcon: "mdi-database",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }